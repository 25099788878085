import styles from '@/components/form/Editable.module.css';
import {openModal} from '@/components/modal/ModalContext';
import {noWhitespaceUnlessLiteralSpace} from '@/model/common/validator';
import {requestAnimationFrameAsync} from '@/utils/animation';
import {constructErrorMeta} from '@/utils/errors';
import {stopPropagationAndPreventDefault} from '@/utils/stopPropagationAndPreventDefault';
import {useSignals} from '@preact/signals-react/runtime';
import {useBlocker} from '@remix-run/react';
import clsx from 'clsx';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  type ForwardedRef,
} from 'react';
import type {ZodTypeAny} from 'zod';

interface EditableProps {
  name: string;
  className?: string;
  validator: ZodTypeAny;
  onEdit: ((value: string, onEditProps: any) => void) | undefined;
  onEditProps?: any;
  children: string;
  spellCheck?: boolean;
  truncate?: boolean;
}

export type EditableRef = {
  click: () => Promise<void>;
  selectAll: () => Promise<void>;
};

export default forwardRef(function Editable(props: EditableProps, ref: ForwardedRef<EditableRef>) {
  useSignals();
  const {
    name,
    className,
    onEdit,
    onEditProps,
    children,
    spellCheck = false,
    truncate,
    validator,
  } = props;
  const [editing, setEditing] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const contenteditableRef = useRef<HTMLDivElement>(null);

  useImperativeHandle<EditableRef, EditableRef>(ref, () => ({
    async click() {
      contenteditableRef.current!.click();
      await requestAnimationFrameAsync(); // wait for pop ups or whatever else that may be open to trigger this click
      contenteditableRef.current!.focus();
    },
    async selectAll() {
      await requestAnimationFrameAsync();
      const range = document.createRange();
      const selection = window.getSelection();

      if (selection) {
        selection.removeAllRanges();

        // Select all content within the editable element
        range.selectNodeContents(contenteditableRef.current!);
        selection.addRange(range);
      }
    },
  }));

  const shouldBlock = useCallback(
    function ({currentLocation, nextLocation}) {
      return !!(
        contenteditableRef.current &&
        editing &&
        currentLocation.pathname !== nextLocation.pathname
      );
    },
    [editing, contenteditableRef],
  );
  const blocker = useBlocker(shouldBlock);

  // TODO move this more centrally maybe
  useEffect(() => {
    if (blocker.state === 'blocked') {
      openModal({
        title: 'Unsaved changes',
        content: 'You have unsaved changes. Would you like to discard them and proceed?',
        styleType: 'destructive',
        confirmButtonContent: 'Proceed',
        onCancelClick() {
          blocker.reset();
        },
        onConfirmClick() {
          contenteditableRef.current!.innerHTML = children;
          if (document.activeElement !== contenteditableRef.current) {
            // blur event won't fire if this has becomed unfocused on mobile
            contenteditableRef.current!.focus();
          }
          contenteditableRef.current!.blur();
          blocker.proceed();
        },
      });
    }
  }, [blocker, children, blocker.state, contenteditableRef]);

  const onReadOnlyClick = useCallback(
    async function () {
      if (onEdit) {
        setEditing(true);
        await requestAnimationFrameAsync();
        contenteditableRef.current!.focus();
      }
    },
    [onEdit, setEditing],
  );

  const onBlur = useCallback(
    function (event) {
      const value = event.currentTarget.textContent;
      event.currentTarget.scrollLeft = 0; // if this is scrolled to the right, blur will cause truncation in the middle of the scroll, leaving the heading in a weird spot
      if (value !== children) {
        try {
          const validated = validator.parse(value);
          const result = onEdit!(validated, onEditProps);
          setEditing(false);
          setErrors([]);
          return result;
        } catch (e) {
          const error = e as Error;
          const errorsMeta = constructErrorMeta(error);
          if (errorsMeta.form?.length) {
            setErrors(errorsMeta.form);
          }
          contenteditableRef.current?.focus();
        }
      } else {
        setErrors([]);
        setEditing(false);
      }
    },
    [children, onEdit, onEditProps, setErrors, setEditing, contenteditableRef, validator],
  );

  const onKeyDown = useCallback(
    function (event) {
      if (event.key === 'Enter') {
        contenteditableRef.current?.blur();
        stopPropagationAndPreventDefault(event);
        return;
      }
      if (event.key === 'Escape') {
        event.currentTarget.innerHTML = children;
        contenteditableRef.current?.blur();
        return;
      }
    },
    [contenteditableRef, children],
  );

  const onBeforeInput = useCallback(function (event) {
    const char = event.data;
    const {success} = noWhitespaceUnlessLiteralSpace.safeParse(char);
    if (!success) {
      stopPropagationAndPreventDefault(event);
    }
  }, []);

  const conditionalClassnames = editing
    ? clsx(
        styles.Editable_textbox_editing,
        'underline underline-offset-4',
        truncate && 'overflow-y-hidden',
        truncate && 'overflow-x-hidden',
        !!errors.length && 'decoration-red-700',
      )
    : clsx(
        onEdit && styles.Editable_textbox_editable,
        onEdit && 'hover:underline hover:decoration-gray-300',
        truncate && 'truncate',
      );

  return (
    <div>
      <div>
        <div
          role="textbox"
          ref={contenteditableRef}
          aria-label={name}
          tabIndex={0}
          className={clsx(
            'Editable flex-shrink underline-offset-4',
            className,
            styles.Editable_textbox,
            conditionalClassnames,
          )}
          contentEditable={editing}
          onBlur={editing ? onBlur : undefined}
          onKeyDown={editing ? onKeyDown : undefined}
          onBeforeInput={editing ? onBeforeInput : undefined}
          spellCheck={spellCheck}
          autoCapitalize="off"
          onClick={editing ? undefined : onReadOnlyClick}
          suppressContentEditableWarning
        >
          {children}
        </div>
      </div>
      {!!errors.length && (
        <div className="text-red-700 animate-fade-in text-base font-serif font-normal leading-normal tracking-normal">
          {errors.map((error, i) => {
            return (
              <div
                id={`${name}-error-${i}`}
                className="pt-1"
                key={`${error}-${i}`}
              >
                {error}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
});
