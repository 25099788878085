import type {AnimationPhase} from '@/components/core/Animation';
import Button from '@/components/core/Button';
import {Dialog, DialogBackdrop, DialogPanel, DialogTitle} from '@headlessui/react';
import {InformationCircleIcon} from '@heroicons/react/20/solid';
import {ExclamationTriangleIcon} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import {useCallback, type MouseEventHandler, type ReactNode} from 'react';

const configsByStyleType: Record<StyleType, any> = {
  default: {
    Icon: InformationCircleIcon,
    iconProps: {
      className: 'text-green-600',
    },
    iconWrapperProps: {
      className: 'bg-green-100',
    },
  },
  destructive: {
    Icon: ExclamationTriangleIcon,
    iconProps: {
      className: 'text-red-600',
    },
    iconWrapperProps: {
      className: 'bg-red-100',
    },
  },
};

export type ModalProps = {
  title: string;
  content: ReactNode | (() => ReactNode);
  styleType?: StyleType;
  className?: string;
  onConfirmClick: MouseEventHandler;
  onCancelClick: MouseEventHandler;
  confirmButtonContent?: ReactNode;
  cancelButtonContent?: ReactNode;
  animationPhase: AnimationPhase;
  loading?: boolean;
};

export type ModalType = keyof typeof configsByStyleType;

export default function Modal(props: ModalProps) {
  const {
    className,
    styleType = 'default',
    title,
    onConfirmClick,
    onCancelClick,
    confirmButtonContent = 'Confirm',
    cancelButtonContent = 'Cancel',
    animationPhase,
    loading = false,
  } = props;
  let {content} = props;
  if (typeof content === 'function') {
    content = content();
  }

  const onDialogClose = useCallback(
    function () {
      if (loading) {
        return;
      }
      // @ts-expect-error this is meant for a mouse event handler, but we will never be able to satisfy this with the way headless UI works
      onCancelClick();
    },
    [loading, onCancelClick],
  );

  const {Icon, iconProps, iconWrapperProps} = configsByStyleType[styleType];

  return (
    <Dialog
      open
      role="dialog"
      onClose={onDialogClose}
      className={clsx('relative z-40', className)}
    >
      <DialogBackdrop
        transition
        className={clsx(
          'fixed inset-0 bg-livan-black bg-opacity-75',
          animationPhase && `Modal-animating-${animationPhase}`,
          animationPhase === 'in' && 'animate-fade-in',
          animationPhase === 'out' && 'animate-fade-out',
        )}
      />

      <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className={clsx(
              'relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl sm:my-8 sm:w-full sm:max-w-lg',
              animationPhase && `DialogPanel-animating-${animationPhase}`,
              animationPhase === 'in' && 'animate-fade-in',
              animationPhase === 'out' && 'animate-fade-out',
            )}
          >
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div
                  {...iconWrapperProps}
                  className={clsx(
                    'mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10',
                    iconWrapperProps.className,
                  )}
                >
                  <Icon
                    aria-hidden="true"
                    {...iconProps}
                    className={clsx(iconProps.className, 'h-6 w-6')}
                  />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle
                    as="h3"
                    className="font-semibold leading-6"
                  >
                    {title}
                  </DialogTitle>
                  <div className="mt-2">{content}</div>
                </div>
              </div>
            </div>
            <div className="bg-gray-100 px-4 py-3 flex sm:px-6 gap-2 justify-center sm:justify-end">
              <Button
                disabled={loading}
                flavor="link"
                onClick={onCancelClick}
              >
                {cancelButtonContent}
              </Button>
              <Button
                loading={loading}
                styleType={styleType}
                onClick={onConfirmClick}
              >
                {confirmButtonContent}
              </Button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
