export function requestAnimationFrameAsync(timeout?: number) {
  return new Promise<void>((resolve) => {
    requestAnimationFrame(() => {
      if (timeout != null) {
        setTimeout(() => {
          resolve();
        }, timeout);
      } else {
        resolve();
      }
    });
  });
}
