import type {UIMatch} from '@remix-run/react';
import {useMatches, useParams} from '@remix-run/react';

import Editable from '@/components/form/Editable';
import type {RouteHandle} from '@/router-types';
import clsx from 'clsx';
import {useEffect, useRef} from 'react';
import {useSignals} from '@preact/signals-react/runtime';

type MainHeadingProps = {
  className?: string;
};

const mainHeadingRef: any = {
  current: null,
};

export async function editMainHeading() {
  mainHeadingRef.current?.click();
}

export function MainHeading(props: MainHeadingProps = {}) {
  useSignals();
  const ref = useRef(null);
  useEffect(() => {
    mainHeadingRef.current = ref.current;
  }, [ref]);
  const {className} = props;
  const params = useParams();
  const matches = useMatches() as UIMatch<unknown, RouteHandle | undefined>[];

  const handle = matches[matches.length - 1].handle;
  if (!handle) {
    return null;
  }
  const {getHeading, editHeadingMeta} = handle;
  const heading = getHeading({params});
  const onEditProps = editHeadingMeta ? {params} : null;
  return (
    <header className={clsx('MainHeading', className, 'truncate')}>
      <h1>
        <Editable
          ref={ref}
          name="heading"
          onEdit={editHeadingMeta?.execute}
          onEditProps={onEditProps}
          // @ts-expect-error not sure how to fix this easily
          validator={editHeadingMeta?.validator}
          truncate
        >
          {heading}
        </Editable>
      </h1>
    </header>
  );
}
