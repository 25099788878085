import type {MouseEventHandler, PropsWithChildren} from 'react';
import type {Signal} from '@preact/signals-react';
import {signal} from '@preact/signals-react';
import type {ModalProps} from '@/components/modal/Modal';
import Modal from '@/components/modal/Modal';
import {useSignals} from '@preact/signals-react/runtime';
import {AnimateOutDurationMs} from '@/components/core/Animation';
import assert from '@/utils/assert';
import {ComponentFromSignal} from '@/utils/signals';
import {handleGenericClientError} from '@/utils/errors';

type ModalSpecProps = Pick<
  ModalProps,
  | 'title'
  | 'content'
  | 'styleType'
  | 'onConfirmClick'
  | 'confirmButtonContent'
  | 'cancelButtonContent'
> &
  Partial<Pick<ModalProps, 'onCancelClick'>>;

type ModalSpec = {
  id: number;
  props: ModalProps;
};

const modalSpecSignals = signal<Signal<ModalSpec>[]>([]);

let modalId = 0;

function updateModalSpecSignal({
  id,
  props,
}: {
  id: ModalSpec['id'];
  props: Partial<ModalSpec['props']>;
}) {
  const modalSpecSignal = modalSpecSignals.value.find((modalSpecSignal) => {
    return modalSpecSignal.value.id === id;
  });
  assert(modalSpecSignal, `modalSpecSignal is required`);
  modalSpecSignal.value = {
    ...modalSpecSignal.value,
    props: {
      ...modalSpecSignal.value.props,
      ...props,
    },
  };
}

type ModalResult = {
  confirmed: true;
  result: any;
  error?: never;
} & {
  confirmed: false;
  result?: never;
  error?: never;
} & {
  confirmed: true;
  error: Error;
  result?: never;
};

export function openModal(
  props: Omit<ModalSpecProps, 'onConfirmClick'> & {
    onConfirmClick?: ModalSpecProps['onConfirmClick'];
  },
): Promise<ModalResult> {
  const id = ++modalId;
  let parentResolve;
  let parentReject;
  const promise = new Promise<ModalResult>((resolve, reject) => {
    parentResolve = resolve;
    parentReject = reject;
  });
  const modalSpec: ModalSpec = {
    id,
    props: {
      ...props,
      className: 'absolute',
      animationPhase: 'in',
      async onConfirmClick(e) {
        updateModalSpecSignal({
          id,
          props: {
            loading: true,
          },
        });
        try {
          const result = await Promise.resolve(props.onConfirmClick?.(e));
          closeModal(id);
          parentResolve({confirmed: true, result});
        } catch (e) {
          const error = e as Error;
          if (handleGenericClientError(error)) {
            closeModal(id);
          } else {
            parentReject(error);
          }
        } finally {
          updateModalSpecSignal({
            id,
            props: {
              loading: false,
            },
          });
        }
      },
      async onCancelClick(e) {
        closeModal(id);
        props.onCancelClick?.(e);
        parentResolve({confirmed: false});
      },
    },
  };
  modalSpecSignals.value = [signal(modalSpec), ...modalSpecSignals.value];
  return promise;
}

export const onClickOpenModal = (props: ModalSpecProps) => {
  const onClick: MouseEventHandler = (e) => {
    e.stopPropagation?.();
    return openModal(props);
  };
  return onClick;
};

export function closeModal(id: ModalSpec['id']) {
  updateModalSpecSignal({
    id,
    props: {
      animationPhase: 'out',
      loading: false,
    },
  });
  setTimeout(() => {
    modalSpecSignals.value = modalSpecSignals.value.filter(
      (modalSignal) => modalSignal.value.id !== id,
    );
  }, AnimateOutDurationMs);
}

export default function ModalContext(props) {
  useSignals();
  const {children} = props;
  return (
    <>
      <div className="ModalContext relative">
        {modalSpecSignals.value.map((modalSpecSignal) => {
          const {id} = modalSpecSignal.peek();
          return (
            <ComponentFromSignal
              key={id}
              Component={Modal}
              signal={modalSpecSignal}
              propsKey="props"
            />
          );
        })}
      </div>
      {children}
    </>
  );
}
